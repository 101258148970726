import {
  SocialAuthenticationSourceTypes,
  SocialAuthenticationTypes,
} from "app/enums/authentication-social.enum";
import { AuthenticationTypes } from "app/enums/authentication-type.enum";
import { EnvironmentConfiguration } from "configuration/environment.configuration";
import { Location, useLocation } from "react-router-dom";
import { ReactComponent as Facebook } from "resources/assets/icons/social/facebook.svg";
import { ReactComponent as Google } from "resources/assets/icons/social/google.svg";
import { ReactComponent as LinkedIn } from "resources/assets/icons/social/linkedin.svg";
import { Button } from "resources/components/buttons/main";
import { SocialButtonProps } from "./types";

export function Social({ signUp }: any): JSX.Element {
  const location: Location = useLocation();

  const socialButtonsMap: SocialButtonProps[] = [
    {
      identifier: SocialAuthenticationTypes.LinkedIn,
      title: "LinkedIn",
      startIcon: <LinkedIn />,
      disabled: true,
    },
    {
      identifier: SocialAuthenticationTypes.Facebook,
      title: "Facebook",
      startIcon: <Facebook />,
      disabled: true,
    },
    {
      identifier: SocialAuthenticationTypes.Google,
      title: "Google",
      startIcon: <Google />,
    },
  ];

  function onSocialSignInPress(provider: SocialAuthenticationTypes): void {
    if (EnvironmentConfiguration.AUTHENTICATION_REDIRECT_URL) {
      const params = new URLSearchParams({
        sso_source: SocialAuthenticationSourceTypes.Web,
        sso_origin: location.pathname,
        sso_type: signUp
          ? AuthenticationTypes.SignUp
          : AuthenticationTypes.SignIn,
      }).toString();

      const url = `${EnvironmentConfiguration.AUTHENTICATION_REDIRECT_URL}/${provider}/redirect?${params}`;

      window.location.href = url;
    }
  }

  return (
    <>
      {socialButtonsMap.map(
        (button: SocialButtonProps, index: number) =>
          !button.disabled && (
            <Button
              key={index}
              title={`${signUp ? "Criar conta" : "Entrar"} com o ${
                button.title
              }`}
              sx={{
                justifyContent: "flex-start !important",
                borderRadius: "12px !important",
                border: "1px solid var(--gray-30, #D1D5DB)",
                background:
                  "var(--foundation-primary-primary-900, #FFFFFF) !important",

                span: {
                  fontFamily: "'InterMedium', sans-serif !important",
                  color: "#1F2937 !important",
                },
              }}
              startIcon={button.startIcon}
              onClick={() => onSocialSignInPress(button.identifier)}
            />
          )
      )}
    </>
  );
}
